<template>
  <div class="layoutPanel">
    <el-scrollbar
      wrap-class="scrollbar-wrapper"
      class="sidebar-container"
      :class="[positonTop ? 'positonTop' : '']"
    >
      <div class="userProfile">
        <span
          :title="user.userName"
          class="headPortrait ellipsis"
        >{{user.userName || '--'}}</span>
        <span class="accountType"><i class="icon-suAccount"></i>{{user.roleName || '--'}}</span>
      </div>
      <el-menu
        router
        unique-opened
        :default-active="activeMenu"
      >
        <template v-for="route in routes.children">
          <el-submenu
            v-if="route.hasOwnProperty('children') && route['children'].length"
            :index="route.path"
            :key="route.path"
          >
            <template slot="title">
              {{route.meta.title}}
            </template>
            <el-menu-item
              v-for="item in route.children"
              :key="item.path"
              :index="item.path"
            >
              {{item.meta.title}}
            </el-menu-item>
          </el-submenu>
          <el-menu-item
            v-else
            :key="route.path"
            :index="route.path"
          >
            {{route.meta.title}}
          </el-menu-item>
        </template>
      </el-menu>
    </el-scrollbar>
    <div class="main-container">
      <breadcrumb class="breadcrumb-container" />
      <transition
        name="fade-transform"
        mode="out-in"
      >
        <router-view :key="key" />
      </transition>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Breadcrumb from '@/components/breadcrumb/index.vue'
export default {
  name: 'Layout',
  components: { Breadcrumb },
  data () {
    return {
      positonTop: false
    }
  },
  computed: {
    routes () {
      const tempArr = this.$router.options.routes.filter(item => {
        if (item.name === 'managerCenter') {
          return true
        } else {
          return false
        }
      })
      return tempArr[0]
    },
    key () {
      return this.$route.path
    },
    activeMenu () {
      const route = this.$route
      const { meta, path } = route
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu
      }
      return path
    },
    ...mapGetters({
      user: 'user'
    })
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  mounted () {
    const clientWidth = `${document.documentElement.clientWidth}`
    if (clientWidth > 1500) {
      this.isPx = '516px'
    } else {
      this.isPx = '448px'
    }
    window.addEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll (e) {
      const scrollTop = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop
      // const scrollPosition = document.documentElement.clientHeight - window.pageYOffset
      if (scrollTop > 88) {
        this.positonTop = true
      } else {
        this.positonTop = false
      }
    }
  }
}
</script>

<style scoped lang="stylus">
.layoutPanel>>>
  overflow hidden
  padding-bottom 16px
  .sidebar-container
    transition width 0.28s
    width 180px !important
    background-color #23252b
    margin-top 15px
    position absolute
    height calc(100% - 136px)
    overflow hidden
    .scrollbar-wrapper
      overflow hidden
    .userProfile
      text-align center
      overflow hidden
      & span
        display block
        color #fff
      .headPortrait
        width 76px
        height 76px
        margin 23px auto 15px auto
        line-height 76px
        font-family '微软雅黑'
        font-weight 600
        font-size 16px
        background linear-gradient(66deg, #D9A45E, #F0D1AC)
        border 1px solid #FFFFFF
        border-radius 50%
      .accountType
        width 90px
        height 22px
        line-height 22px
        padding 2px 3px
        margin 0 auto
        background rgba(224, 188, 140, 0.05)
        border 1px solid #DEBA88
        border-radius 15px
    .el-menu
      background-color #23252b
      border-right none
      margin-top 20px
      li.el-submenu__title:hover
        background-color #33343a !important
      .el-menu-item, .el-submenu__title
        font-size 16px
        color #fff
        border-left 3px solid #23252b
        &:hover
          border-left 3px solid #FFA134
          background-color #33343a !important
          color #fff !important
      .el-menu-item:focus
        background none
      .is-active
        border-left 3px solid #FFA134
        background-color #33343a !important
        color #fff
      .is-opened
        border none
        background none
    .el-submenu
      .el-menu
        margin 0
  .main-container
    width calc(100% - 226px)
    min-height 100%
    transition margin-left 0.28s
    color #fff
    padding-top 15px
    margin-left 204px
    position relative
  .positonTop
    margin-top 0
    position fixed
    left 0
    top 0
    height 100%
    z-index 11
    ul
      min-height 100%
      background-color #23252B
      margin-top 10px
</style>
